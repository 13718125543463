/**
 * Function to return a number of payments given the principal, interest rate,
 * and (monthly) payment amount
 *
 * For values that would yield an infinite number of payments, a NaN is
 * returned
 *
 * (1 - (amount * j) / payment) could result in a negative value for the log10
 * function which is NaN.
 *
 * @param amount - principal amount
 * @param interest - yearly interest rate
 * @param payment - monthly amount
 * @returns {null|number|NaN}
 */
export default function(amount, interest, payment) {
  if (amount == null || interest == null || payment == null) {
    return null;
  }
  if (isNaN(amount) || isNaN(interest) || isNaN(payment)) {
    return null;
  }
  if (amount <= 0 || interest < 0 || payment <= 0) {
    return null;
  }
  if( interest == 0 ) {
    return Math.ceil(amount / payment)
  }
  const j = interest / (12 * 100); // interest is percent, year-based
  return Math.ceil(
    Math.log10(1 / (1 - (amount * j) / payment) ) / Math.log10(1 + j)
  );
}
