<template>
  <div class="message is-info" v-if="show">
    <div class="message-header">
      <p v-if="$store.state.parrng.results['catch-all']">
        {{ $store.state.parrng.results["catch-all"].message }}
      </p>
      <button class="delete" @click="$emit('update-show', false)"></button>
    </div>
    <div class="message-body content" v-if="$store.state.parrng.results">
      <p>{{ $store.state.instanceConfig.notApprovedHeader }}</p>
      <ol>
        <li>
          {{ $store.state.instanceConfig.notApprovedAmount }}
          {{ $store.state.instanceConfig.notApprovedHeader }}
          {{
            $store.state.parrng.results["catch-all"].retvals[
              $store.state.parrng.retVals.PORTAL_OVER_TIME_AMOUNT
            ] | formatCurrency
          }}
        </li>
        <li>
          {{ $store.state.instanceConfig.notApprovedNumber }}
          {{
            $store.state.parrng.results["catch-all"].retvals[
              $store.state.parrng.retVals.PORTAL_OVER_TIME_NUMBER
            ]
          }}
        </li>
        <li>
          {{ $store.state.instanceConfig.notApprovedFirst }}
          {{
            $store.state.parrng.results["catch-all"].retvals[
              $store.state.parrng.retVals.PORTAL_OVER_TIME_FIRST
            ] | formatDate
          }}
        </li>
      </ol>
      <button class="button is-success" @click="useRetVals">
        {{ $store.state.instanceConfig.notApprovedButton }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: ["show"],
  methods: {
    useRetVals() {
      this.$emit("use-retvals");
    }
  }
};
</script>
