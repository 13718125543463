<template>
  <base-layout :loadingFlag="$store.state.loading">
    <breadcrumbs :links="links" />
    <h1 class="title">{{ instanceConfig.offerTitle }}</h1>
    <hr />
    <not-approved
      :show="showNotApproved"
      @update-show="hideNotApproved"
      @use-retvals="useRetVals"
    />
    <p class="content">
      {{ instanceConfig.offerContent }}
    </p>
    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <div>
            <p class="heading">{{ instanceConfig.overTimeAmountLabel }}</p>
            <p class="title">{{ arrangedAmount | formatCurrency }}</p>
          </div>
        </div>
      </div>
    </div>
    <validation-observer class="container" v-slot="{ invalid }">
      <validation-provider tag="div" class="field" rules="required|notNaN" v-slot="{ failed, errors }">
        <label class="label">
          {{ instanceConfig.affordLabel }}
        </label>
        <div class="control">
          <input
              name="Amount"
              type="text"
              class="input"
              :class="{ 'is-danger': isNaN(overTimeNumber) }"
              v-model.lazy="overTimeAmount"
              :disabled="$store.state.loading"
              ref="tfOverTimeAmount"
          />
          <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
          <small v-if="instanceConfig.affordSmall"
            ><em>{{ instanceConfig.affordSmall }}</em></small
          >
        </div>
      </validation-provider>
      <validation-provider tag="div" class="field" rules="required|numeric" v-slot="{ failed, errors }">
        <label class="label">
          {{ instanceConfig.numberLabel }}
        </label>
        <div class="control">
          <input
              name="Number"
              type="text"
              class="input"
              v-model.lazy="overTimeNumber"
              :disabled="$store.state.loading"/>
          <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
          <small v-if="instanceConfig.numberSmall"
            ><em>{{ instanceConfig.numberSmall }}</em></small
          >
        </div>
      </validation-provider>
      <validation-provider tag="div" class="field" rules="required">
        <label class="label">
          {{ instanceConfig.firstLabel }}
        </label>
        <div class="control">
          <input type="date" class="input" v-model="overTimeFirst" :disabled="$store.state.loading"/>
          <small v-if="instanceConfig.firstSmall"
            ><em>{{ instanceConfig.firstSmall }}</em></small
          >
        </div>
      </validation-provider>
      <div class="buttons">
        <button
          class="button is-success"
          @click="submit"
          :disabled="invalid || $store.state.loading"
        >
          Submit
        </button>
        <router-link
          tag="button"
          class="button"
          :to="{ name: 'makePayment', params: { id: $route.params.id } }"
          >Cancel</router-link
        >
      </div>
    </validation-observer>
  </base-layout>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import BaseLayout from "@/components/BaseLayout.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import NotApproved from "./NotApproved.vue";
import Controller from "@/views/payment/overtime/OverTimeController";
import computePaymentAmount from "@/utils/ComputePaymentAmount";
import computeNumberOfPayments from "@/utils/ComputeNumberOfPayments";

export default {
  components: {
    BaseLayout,
    Breadcrumbs,
    ValidationProvider,
    ValidationObserver,
    NotApproved
  },
  computed: {
    ...mapState(["instanceConfig"]),
    ...mapState("parrng", ["results"]),
    ...mapState("arr", ["preview"]),
    ...mapState("accounts", ["interest"]),
    ...mapGetters("accounts", ["fullAccount", "dueValue"])
  },
  data() {
    return {
      overTimeAmount: null,
      overTimeNumber: null,
      overTimeFirst: null,
      showNotApproved: false,
      links: [],
      suppressAmountListeners: false,
      suppressNumberListeners: false,
      arrangedAmount: null
    };
  },
  created() {
    this.links = [
      { label: "Home", icon: "home", to: { name: "home" } },
      {
        label: "Make Payment",
        icon: "dollar-sign",
        to: { name: "makePayment", params: { id: this.$route.params.id } }
      },
      {
        label: this.instanceConfig.overTimeTitle, // needs to be done in created()
        icon: "calendar",
        to: { name: "overTime", params: { id: this.$route.params.id } },
        isActive: true
      }
    ];
  },
  methods: {
    ...mapActions("parrng", [
      "callPARRNG",
      "setPARRNGFields"
    ]),
    ...mapActions("arr", ["previewArrangement"]),
    async submit() {
      await Controller.submit.call(this);
    },
    useRetVals() {
      this.overTimeAmount = this.$store.state.parrng.results[
        "catch-all"
      ].retvals[this.$store.state.parrng.retVals.PORTAL_OVER_TIME_AMOUNT];
      this.overTimeNumber = this.$store.state.parrng.results[
        "catch-all"
      ].retvals[this.$store.state.parrng.retVals.PORTAL_OVER_TIME_NUMBER];
      this.overTimeFirst = this.$store.state.parrng.results[
        "catch-all"
      ].retvals[this.$store.state.parrng.retVals.PORTAL_OVER_TIME_FIRST];
    },
    hideNotApproved() {
      this.showNotApproved = false;
    }
  },
  watch: {
    overTimeAmount(val) {
      if (val && !this.suppressAmountListeners) {
        this.suppressNumberListeners = true;
        const rowPOTDue = this.$store.state.globalConfig.rowPOTDue
        const total = this.fullAccount(this.$route.params.id)[rowPOTDue].value;
        const npay = computeNumberOfPayments(
            total,
            this.interest.interestRate,
            val
        )
        if( !isNaN(npay) ) {
          this.overTimeNumber = npay
        } else {
          this.overTimeNumber = 'Amount entered is too low'
        }
      }
      if (this.suppressAmountListeners) {
        this.suppressAmountListeners = false;
      }
    },
    overTimeNumber(val) {
      if (val && !this.suppressNumberListeners) {
        this.suppressAmountListeners = true
        const rowPOTDue = this.$store.state.globalConfig.rowPOTDue
        const total = this.fullAccount(this.$route.params.id)[rowPOTDue].value
        const amt = computePaymentAmount(
          total,
          this.interest.interestRate,
          val
        )
        if( amt && !isNaN(amt) ) {
          this.overTimeAmount = amt.toFixed(2)
        } else {
          this.overTimeAmount = amt
        }
      }
      if (this.suppressNumberListeners) {
        this.suppressNumberListeners = false
      }
    }
  },
  beforeRouteEnter: Controller.beforeRouteEnter
};
</script>
