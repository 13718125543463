import fetchOverTimePage from "@/activities/FetchOverTimePage";

export default {
  beforeRouteEnter: function(to, from, next) {
    next(async vm => {
      vm.showNotApproved = false;
      vm.$store.commit("SET_LOADING", true);
      window.scrollTo(0, 0);
      try {
        await fetchOverTimePage({
          instanceId: vm.$route.params.instanceId,
          accountNo: vm.$route.params.id
        })
        const rowPOTDue = vm.$store.state.globalConfig.rowPOTDue
        vm.arrangedAmount = vm.fullAccount(vm.$route.params.id)[rowPOTDue].value;
      } catch (e) {
        vm.$store.commit("SET_GLOBAL_NOTIFICATION", {
          showNotification: true,
          notificationMessage: e,
          notificationType: "error"
        });
      } finally {
        vm.$store.commit("SET_LOADING", false);
      }
    });
  },
  async submit() {
    window.scrollTo(0, 0);
    this.showNotApproved = false;

    this.$store.commit("SET_LOADING", true);

    try {
      await this.setPARRNGFields({
        paymentAmount: this.overTimeAmount,
        numberOfPayments: this.overTimeNumber,
        firstPaymentDate: this.overTimeFirst
      });

      await this.callPARRNG({
        sodCode: this.$store.state.instanceConfig.negotiatorPARRNG,
        relatedAccount: this.$route.params.id,
        overTimeAmount: this.overTimeAmount,
        overTimeNumber: this.overTimeNumber,
        overTimeFirst: this.overTimeFirst
      });
      if (this.results && this.results.success) {
        // #1706 also check the schedule

        const rowPOTDue = this.$store.state.globalConfig.rowPOTDue

        let totalAmount = this.fullAccount(this.$route.params.id)[rowPOTDue].value;

        await this.previewArrangement({
          username: this.$store.state.username,
          jwt: this.$store.state.jwt,
          accountNo: this.$route.params.id,
          paymentAmount: this.$store.state.parrng.paymentAmount,
          numberOfPayments: this.$store.state.parrng.numberOfPayments,
          firstPaymentDate: this.$store.state.parrng.firstPaymentDate,
          planCode: this.$store.state.instanceConfig.pprCode,
          totalAmount: totalAmount,
          timezone: (-1 * new Date().getTimezoneOffset()) / 60,
          fee: 0.0 // will compute later
        });

        if (
          this.preview &&
          this.preview.length > 0 &&
          this.preview[this.preview.length - 1].balance == 0
        ) {
          this.$router.push({ name: "amortizationSchedule" });
        } else {
          this.$store.commit("SET_GLOBAL_NOTIFICATION", {
            showNotification: true,
            notificationMessage:
              "The entered parameters do not produce a full arrangement",
            notificationType: "error"
          });
        }
      } else {
        this.showNotApproved = true;
      }
    } catch (e) {
      this.$store.commit("SET_GLOBAL_NOTIFICATION", {
        showNotification: true,
        notificationMessage: e,
        notificationType: "error"
      });
    } finally {
      this.$store.commit("SET_LOADING", false);
    }
  }
};
