import axios from "axios";
import store from "../store/index";

export default async function({ instanceId, accountNo }) {
  let { data } = await axios.get(
    process.env.VUE_APP_API_URL +
      "api/pages/" +
      instanceId +
      ";user=" +
      store.state.username,
    {
      params: {
        pageName: "OverTime",
        accountNo: accountNo
      },
      headers: {
        Authorization: "Bearer " + store.state.jwt
      }
    }
  );

  store.commit("SET_GLOBAL_CONFIG", data.globalConfig);
  store.commit("SET_INSTANCE_CONFIG", data.instanceConfig);
  store.commit("accounts/SET_ACCOUNTS", data.accounts);
  store.commit("parrng/SET_FORM_FIELDS", data.formFields);
  store.commit("parrng/SET_PROMPTS", data.prompts);
  store.commit("accounts/SET_INTEREST", data.interest);
}
