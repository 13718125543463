/**
 * Function to return a monthly payment amount given the principal, yearly
 * interest rate, and number of monthly payments
 *
 * @param amount
 * @param interest
 * @param nPayments
 * @returns {null|number}
 */
export default function(amount, interest, nPayments) {
  if (amount == null || interest == null || nPayments == null) {
    return null;
  }
  if (isNaN(amount) || isNaN(interest) || isNaN(nPayments)) {
    return null;
  }
  if (amount <= 0 || interest < 0 || nPayments <= 0) {
    return null;
  }
  if( interest == 0 ) {
    return amount / nPayments
  }
  const j = interest / (12 * 100);
  const payment = amount * (j / (1 - Math.pow(1 + j, -nPayments)));
  return payment;
}
