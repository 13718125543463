var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',{attrs:{"loadingFlag":_vm.$store.state.loading}},[_c('breadcrumbs',{attrs:{"links":_vm.links}}),_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.instanceConfig.offerTitle))]),_c('hr'),_c('not-approved',{attrs:{"show":_vm.showNotApproved},on:{"update-show":_vm.hideNotApproved,"use-retvals":_vm.useRetVals}}),_c('p',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.instanceConfig.offerContent)+" ")]),_c('div',{staticClass:"level is-mobile"},[_c('div',{staticClass:"level-left"},[_c('div',{staticClass:"level-item"},[_c('div',[_c('p',{staticClass:"heading"},[_vm._v(_vm._s(_vm.instanceConfig.overTimeAmountLabel))]),_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm._f("formatCurrency")(_vm.arrangedAmount)))])])])])]),_c('validation-observer',{staticClass:"container",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"required|notNaN"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.instanceConfig.affordLabel)+" ")]),_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.overTimeAmount),expression:"overTimeAmount",modifiers:{"lazy":true}}],ref:"tfOverTimeAmount",staticClass:"input",class:{ 'is-danger': isNaN(_vm.overTimeNumber) },attrs:{"name":"Amount","type":"text","disabled":_vm.$store.state.loading},domProps:{"value":(_vm.overTimeAmount)},on:{"change":function($event){_vm.overTimeAmount=$event.target.value}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),(_vm.instanceConfig.affordSmall)?_c('small',[_c('em',[_vm._v(_vm._s(_vm.instanceConfig.affordSmall))])]):_vm._e()])]}}],null,true)}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.instanceConfig.numberLabel)+" ")]),_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.overTimeNumber),expression:"overTimeNumber",modifiers:{"lazy":true}}],staticClass:"input",attrs:{"name":"Number","type":"text","disabled":_vm.$store.state.loading},domProps:{"value":(_vm.overTimeNumber)},on:{"change":function($event){_vm.overTimeNumber=$event.target.value}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),(_vm.instanceConfig.numberSmall)?_c('small',[_c('em',[_vm._v(_vm._s(_vm.instanceConfig.numberSmall))])]):_vm._e()])]}}],null,true)}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"required"}},[_c('label',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.instanceConfig.firstLabel)+" ")]),_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.overTimeFirst),expression:"overTimeFirst"}],staticClass:"input",attrs:{"type":"date","disabled":_vm.$store.state.loading},domProps:{"value":(_vm.overTimeFirst)},on:{"input":function($event){if($event.target.composing){ return; }_vm.overTimeFirst=$event.target.value}}}),(_vm.instanceConfig.firstSmall)?_c('small',[_c('em',[_vm._v(_vm._s(_vm.instanceConfig.firstSmall))])]):_vm._e()])]),_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"button is-success",attrs:{"disabled":invalid || _vm.$store.state.loading},on:{"click":_vm.submit}},[_vm._v(" Submit ")]),_c('router-link',{staticClass:"button",attrs:{"tag":"button","to":{ name: 'makePayment', params: { id: _vm.$route.params.id } }}},[_vm._v("Cancel")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }